@import url("https://p.typekit.net/p.css?s=1&k=afb3bbf&ht=tk&f=35204.35205.35206.35207.35208.35209.35210&a=8524055&app=typekit&e=css");




@font-face {
    font-family:"bicyclette";
    src:url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
    }


.acc{
    padding: 0px;
    height: auto;
    width: auto;
    font-style: "bicyclette" ;
}

ul.menuu{
    color:white;
    padding-top:234px;
    
} 

.oods.col-sm-2{
    background:black;
    padding:  2px;
     height:100vh
}
.cont-act-for.col-sm-10{
    padding-left: 38px;

}
p.a-info{
    
    font-weight: 600;
    padding-left: 50px;
    font-size: 19px;
    padding-top: 5px;
}

h3.samt{
    padding-left: 45px;
    color: orange;
    font-size: 31px;
    font-weight: bold;
    padding-top: 65px;
    padding-bottom: 14px;

}
@media only screen and (max-width:610px ) {

.oods.col-sm-2 {
    display: none!important;
}
.cont-act-for.col-sm-10 {
    padding-left: 2px;
}
h3.samt{
    padding-top: 0px;
    padding-left: 20px;
    font-size: 35px;
    padding-bottom: 0px;
    margin-top: -51px;

}
p.a-info {
    font-weight: 600;
    padding-left: 24px;
    font-size: 22px;
    padding-top: 5px;
}
}