@import url("https://p.typekit.net/p.css?s=1&k=afb3bbf&ht=tk&f=35204.35205.35206.35207.35208.35209.35210&a=8524055&app=typekit&e=css");




@font-face {
    font-family:"bicyclette";
    src:url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
    }

p.as-info{
    
    font-weight: 600;
    padding-left: 0px;
    font-size: 19px;
    padding-top: 0px;
   
}

h3.bcm{
    padding-left: 0px;
    color: orange;
    font-size: 31px;
    font-weight: bold;
    padding-top: 65px;
    padding-bottom: 4px;
    

}
.acc1{
    padding: 0px;
    height: auto;
    width: auto;
    font-style: "bicyclette" ;
}


/* div.col-3.pro{
    float: left;
    width: 50%;
    padding: 10px;
    height: auto; 
    padding-left: 51px;

} */
.formA-control {
    display: block;
    width: 55%;
    padding: 0.375rem 0.75rem;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1rem;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid#cdd4dc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} 

/* .ooto.col-sm-2{
   
    padding: 1%;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    height:100vh
} */

.confs-for.col-sm-10{
    padding-left: 63px;
}

.control-label.col-sm-2{
    padding-left: 45px;
    padding-top:15px;
}

input[type="link"]{
    padding-left: 45px;
}


.fok-group{
    margin-top: -51px;
    padding-left: 0px;
}
.cot-for{
    padding-left: 0px;
}

a.btn-btn{
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: white;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: orange;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 20px;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
padding-right: 2rem!important;
    padding-left: 2rem!important;
   padding-top: 0.1rem;
    padding-bottom: 0.1rem;



}
@media only screen and (max-width:610px ) {
    .nav.col-sm-2 {
       display: none!important;
    }
    .confs-for.col-sm-10 {
        padding-left: 39px;
    }
    p.as-info {
        font-weight: 600;
        padding-left: 18px;
        font-size: 20px;
        padding-top: 0px;
    }
    .cot-for {
        padding-left: 21px;
    }
    .formA-control {
        display: block;
        width: 92%;
    }
    .fok-group {
        margin-top: -51px;
        padding-left: 18px;
    }
    h3.bcm {
       
        padding-top: 0px;
        padding-left: 15px;
    margin-top: -58px;
    font-size: 33px;

        
    }
    a.btn-btn {
        font-size: 17px;
        padding-right: 1rem!important;
        padding-left: 1rem!important;
    }
}  