.product-btn-default-single-quick {
    display: inline-block;
    font-weight: 400;
    width: 50%;
    line-height: 1.5;
    color: black;
    letter-spacing: 3px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: orange;
    border: 1px solid transparent;
    /* padding: 10.375rem -4.25rem; */
    font-size: 14px;
    /* border-radius: 0.25rem; */
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding-right: 7rem!important;
    padding-left: 2rem!important;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}
.sub-scribe-quick{
    margin-top: -56px;
}
.product-btn-subs-add-quick {
    color: orange;
    border-radius: 29px;
    /* border-color: black; */
    background-color: white;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 15px;
    letter-spacing: 3px;
    border: 1px solid;
}

.popup-box-quick {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
  span.close-icon {
    content: 'x';
    margin-right: 86px;
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
   
  .box-quick{
    position: relative;
    width: 65%;
    margin: 0 auto;
    height: auto;
    max-height: 90vh;
    margin-top: calc(100vh - 90vh - 30px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
   
  }

.col-sm-for{
    float: right;
    padding-left: -54px;
    margin-top: -70px;
    color: black;
    font-size: 48px;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}
.ask-add-quick {
    padding-left: 4px;
    padding-top: 10px;
}
.part-quick{
position: relative;
    height: 589px;
    overflow: hidden;
    background-color:#8080802e;
    margin-left: 27px;
}
h3.pro-duct-quick {
    font-size: 30px;
    font-weight: bold;
    color:orange;
    padding-left: 37px;
    
}
div.pro-duct-only-quick{
    font-size: 30px;
    font-weight: bold;
    color:orange;
}