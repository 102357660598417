div.Login form{
    width: 80%;
    margin-top: 130px;
    margin-left: 16%;
    height: 100vh;
}


div.smedia{
    display: flex;
    column-gap: 24px;
}