@import url("https://p.typekit.net/p.css?s=1&k=afb3bbf&ht=tk&f=35204.35205.35206.35207.35208.35209.35210&a=8524055&app=typekit&e=css");




@font-face {
    font-family:"bicyclette";
    src:url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
    }


 .tol.col-sm-2{
    background:black;
    padding: 2px;
   
    height:100vh
}
ul.menus{
    color:white;
    padding-top:248px;
    font-weight:bold;
    font-size: 1.2vw;
    line-height: 44px;
    list-style: none;
    text-decoration:none;
    
} 
div.col-sm-10.co-form{
    padding-left: 20px;
}
h3.subp{
    padding-left: 50px;
    color: orange;
    font-size: 35px;
    font-weight: bold;
    padding-top: 65px;
    padding-bottom: 14px;

} 

p.ts-info{
    
    font-weight: 600;
    padding-left: 50px;
    font-size: 20px;
    padding-top: 5px;
}
p.pass-sub{
    font-weight: 600;
    padding-left: 50px;
    font-size: 20px;
    padding-top: 5px;

}

/* .sub{
    padding: 0px;
    height: auto;
    width: auto;
    font-style: "bicyclette" ;
} */
div.column-4 {
        font-weight: bold;
        float: left;
        width: 46%;
        padding: 10px;
        height: auto;
        padding-left: 51px;
}
div.column-1 {
    font-weight: bold;
    float: left;
    width: 16%;
    padding: 10px;
    height: auto; 
  
}
div.column-3 {
    font-weight: bold;
    float: left;
    width: 25%;
    padding: 10px;
    height: auto; 
  
}
div.column-2 {
    font-weight: bold; 
    float: left;
    width: 9%;
    padding: 10px;
    height: auto; 
  
}

.fr-group{
    padding-left: 45px;
  
}

input.btn-default{
   display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color:black;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: orange;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 20px;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
padding-right: 2rem!important;
    padding-left: 2rem!important;
   padding-top: 0.1rem;
    padding-bottom: 0.1rem;

}




@media only screen and (max-width:610px ) {

.tol.col-sm-2 {
    display: none!important;
}
h3.subp{
    padding-left: 14px;
    padding-top: 0px;
    margin-top: -58px;
    font-size: 33px;
padding-bottom: 0px;
}
p.ts-info{
    
    padding-left: 17px;
    font-size: 23px;
    padding-bottom: 10px;

}
div.column-1 {
    
    width: 11%;
}
p.pass-sub {
    font-weight: 600;
    padding-left: 18px;
    font-size: 22px;
    padding-top: 5px;
}
.fr-group {
    padding-left: 21px;
    padding-top: 5px;
}
div.column-3 {
    
    width: 35%;
}
div.column-4 {
   
    width: 27%;
   
    padding-left: 18px;
}
div.col-sm-10.co-form {
    padding-left: 8px;
}
input.btn-default{
    
    font-size: 17px;
   
    padding-right: 1rem!important;
    padding-left: 1rem!important;
   
}
}