div.Signinthree form{
    width: 80%;
    margin-top: 130px;
    margin-left: 16%;
    height: 100vh;
}

.vbox{
    display: flex;
    
}


div.Signinthree .code-input{
 width: 40px;
 margin:0 3px
}