@import url("https://p.typekit.net/p.css?s=1&k=afb3bbf&ht=tk&f=35204.35205.35206.35207.35208.35209.35210&a=8524055&app=typekit&e=css");




@font-face {
  font-family:"bicyclette";
  src:url("https://use.typekit.net/af/39f12b/00000000000000007735acd8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/39f12b/00000000000000007735acd8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/39f12b/00000000000000007735acd8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
  }

 body{
  font-family:"bicyclette";
  overflow-x: hidden;
 }  

 
 

.bgblack {
  background-color:black;
  height: 95px;
  color:gray;
  padding: 11px;
  margin:0 6px;
  white-space:break-spaces;
  text-align: left;
  font-size:1.1vw;
  overflow: hidden;
  
}

.carousel .thumb{
  text-align: -webkit-center;
}


ul .thumbs animated {
  width: 134px;
  padding-left: 171px;
}

.carousel .thumbs-wrapper {
  margin: 0;
  margin-top: -100px
}



.image-content {
  position: absolute;
  /* top: 58%; */
  bottom: 26%;
  left: 8.69%;
  text-align: left;
}

.image-content-above-read {
  position: absolute;
  top: 58%;
  /* left: 8.69%; */
  text-align: left;
}

.image-content2 {
  position: absolute;
  top: 535px;
  left: 171px;
  text-align: left;
}

.section-one .carousel .thumb{
  padding: 0px;
  border:none;
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: none;  

}

@-webkit-keyframes stripes {
  to {
    background-size:100% 100%;
  }
}

@keyframes stripes {
  to {
    background-size:100% 100%;
  }
}

.carousel .thumb.selected .bgblack{

}

/* .carousel.carousel-slider .control-arrow{
  display: none ;
} */

.carousel .thumb.selected .bgblack span{
  text-align: left;
  background: -webkit-linear-gradient(orange , orange) white no-repeat 0 0;
  background: linear-gradient( orange , orange) white no-repeat 0 0;
  background-size: 0 100%;
  -webkit-animation: stripes 4s linear infinite;
  animation: stripes 4s linear infinite;
}

.carousel .thumb.selected .bgblack span b{
  background: black;
  display: table;
  color: white;
  mix-blend-mode: multiply;
}

.carousel .thumb.selected a{
  display: block;
}

.carousel .thumb.selected .upper-text{
  display: block;
}

.carousel .thumb .upper-text{
  display: none;
}


.carousel .thumb a{
  display: none;
}

.carousel .thumbs-wrapper{
  overflow: visible;
} 

.section-two {
  margin-top: -42px;
  

}

.section-two-one {
  background-color: black;
  color: white;
  text-align: left;
  padding: 103px 0px 106px 159px;
  font-size:larger;
  padding-left: 129px;

}

.section-two-two {
  background-color: black;
  text-align: center;
  padding-top: 223px;
}

.section-two-two button {
  padding: 10px 15px;
  margin: 36px;
  background-color: transparent;
  border: 2px solid white;
  color: white;;
 
}


.carousel.carousel-thumbs-top {
  padding-top: 60px;
  background-color: black;
  color: white;

}

.carousel.carousel-thumbs-top .carousel-indicators {
  top: -11px;
  bottom: auto;


}

.carousel .carousel-indicators button {
  width: 100px ;

}

.carousel slide .carousel-indicators.button.active {
  background-color: black;
}

.carousel .carousel-indicators button {
  width: 50px;
}


.carousel .grid-container {
  display: grid;
  column-gap: 180px;
  grid-template-columns: auto auto auto auto;

}

.carousel .grid-container .grid-item {
  width: 10%
}

.carousel-indicators [data-bs-target] {
  text-indent: -10px;
  background-color: black;
  margin: 0 180px;
}

#carouselExampleIndicatorsLeft #sliding .active .active_state {
  width: 0;
  height: 0;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-top: 35px solid rgb(0, 0, 0);
  background-color: transparent;
  margin:auto
}

.carousel-indicators .active {
  font-weight: bold;
}

.section-four-button {
font-size:20px;
  background-color: orange;
  color: white;
  border: none;
  padding: 10px 19px;

}

.section-four-one {
  background-color: black;
  color: white;
  text-align: left;
  padding: 103px 0px 106px 159px;
  background-color: black;
  line-height: 37px;
  font-size:33px;
  /* padding: 100px;
  padding-left: 250px; */
}

.section-four-two {
  background-color: black;
  /* padding: 28px; */
  text-align: center;
  padding-top: 133px;
}

  
.row{
  --bs-gutter-x:0 rem;
  
}

Carousel ol,ul.thumbs{
  padding: 0 158px;
  margin:-148px -50px 0px -50px;
}


li .thumb.selected picture a{
  display: none;
}

.carousel-indicators [data-bs-target]{
  margin:0;
}

div.thumbs-wrapper ul{
  text-align: center;
}

div.slider-one-text{
  position: relative;
  left: 20%;
  top: 80%;
}

div.focus-text{
  text-align: left;
  position: relative;
  top:-400px;
  right:-750px;
  font-size: 25px;

}

.tabs2 p{
  font-size: 30px;
}

.EPR-text{
  position:relative;
  left:-90px;
  top:20px;
  font-size:170%;
  color:white;
}

 .EPR-button{
  background-color:rgb(0,0,0,0.5);
  border:1px solid white;
  padding:12px 30px;
  font-size:16px;
 }


 .navbar-collapse {
   /* background-color: orange; */
   text-align: end  ;
 }

 

 .section-one .carousel .thumb{
  overflow: visible;
}


br.remove{
  display: none!important;
}

div.mobile-golf-text{
  display: none;
}

p.golf-text{
  display: block;
}

div.image-content-above-read{
  display: none!important;
}

div.image-content{
  display: block!important;
}

 @media only screen and (max-width:575px){

  div.image-content-above-read{
    display: block!important;
  }

  div.image-content{
    display: none!important;
  }

  .carousel .thumb.selected a {
    width: 100% !important;
}

  .bgblack{
       font-size: 2.1vw;
       margin: 0!important;
    }


  br.remove{
    display: block!important;
  }

  p.golf-text{
    display: none;
  }
  div.mobile-golf-text{
    display: block;
  }
  
  
  .carousel li.thumb.selected{
    margin-left: 30px!important;
 }

 .img-fish{
   height: 77vh!important;
 }
 .img-road{
  height: 77vh!important;
}
.img-uncle{
  height: 77vh!important;
}
.img-plants{
  height: 77vh!important;
}
.img-meditation{
  height: 77vh!important;
}
.img-golf-crop{
  height: 85vh!important;
}

.img-old{
  height: 85vh!important;
}
.img-party{
  height: 85vh!important;
}

.img-snowboard{
  height: 85vh!important;
}



.image-content{
  position: absolute!important;
    top: 56%!important;
    left: 5.69%!important;
    text-align: left!important; 
}

.image-content p span{
  font-size: 10vw!important;
}

.image-content-above-read{
  position: absolute!important;
    top: -83%!important;
    /* left: 5.69%!important; */
    text-align: left!important;
}

.image-content-above-read p span{
  font-size: 10vw!important;
  line-height: 29px;
}

.section-two-one{
  /* padding: 60px; */
  /* padding-left: 10px; */
  text-align: left;
  /* padding-top: 45px; */
  padding: 45px 0px 0px 27px;
  }

.section-two-two{
  padding: 0px;
  padding-top: 0px;
  text-align: left;
  padding-left: 16px;
  }

  br.removenot{
    display: none;
  }

  .section-two-two button{
    margin:10px;
    font-weight: bolder;
  }

  .section-two-two button {
    padding: 7px 6px;
    /* margin: 36px; */
    background-color: orange;
    /* border: 5px solid white; */
    color: black;
}



div#hide1{
  display: none;
}
div#hide2{
  display: block!important;
}

div.dont-display-line{
  display: flex!important;
  justify-content: space-between!important;
  align-items: center!important;
  align-content: space-evenly!important;
}   

.tabs2 p {
  font-size: 13px!important;
  border: 2px solid white;
  padding: 5px 5px 5px 12px;
  margin: 0px 11px;
}

.carousel.carousel-thumbs-top {
  padding-top: 10px;
  background-color: black;
  color: white;


 }

 .carousel.carousel-thumbs-top .carousel-indicators {
  bottom: auto;
  /* top: -11px; */
  /* position: sticky!important; */
  top: 85px!important;

}

.tabs2.active{
  color: black;
 
}

.tabs2.active p{
  background-color: white;
 
}

.caption-1 .carousel-caption {
  position: absolute;
  right: 0px;
  bottom: 1.25rem;
  left: 5%!important;
  top: 16%!important;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: left;
  bottom: 57%!important;
  font-size: 8.6vw!important;
 }

button.newbutton{
  display: none;
}
.section-four-one{

  padding-left: 33px!important;
  text-align:left!important;
  padding-bottom: 0px!important;
}

.section-four-button{
  display: none;
}

.caption-2 .carousel-caption{
  font-size: 25px!important;
}

.carousel li.thumb.selected{
   width: 49%!important ;
}

}
@media only screen and (max-width:575px ) {
 

   
     
     .section-two-one:first-child{
margin-top:-14px;
margin-bottom: 0px;
     }
  

    .section-four-two{
      padding: 60px;
      padding-top: 50px;
      text-align: center;
      /* margin-top: 35px; */
      }

      .section-four-one{
        
        padding: 34px 0px 44px 0px;;
        padding-top: 50px;
        text-align: center;
        
        }

        
          
        .EPR-text{
          position:relative;
          left:-66px;
          top:37px;
          font-size:110%;
          color:white;
        }

        .EPR-button{
          background-color:rgb(0,0,0,0.5);
          border:1px solid white;
          padding:5px 12px;
          font-size:16px;
         }

         .section-four-one{
          /* padding-top: 45px; */
          font-size: x-large;
         }

         
       
}

@media only screen and (max-width:900px ) {
  .section-four-one {
     font-size: 27px;
    }


  .tabs2 p {
    font-size: 20px;
}
}


@media only screen and (max-width:378px) {
  .section-two-one{
    padding-left: -1px;
  }

  .img1{
    margin-left:-30px;
  }
}

@media only screen and (max-width:323px) {
  .section-four-two{
    
  }

  .img1{
    margin-left:-52px;
  }

  .tabs2 p {
    font-size: 13px;
    margin-top: 20px;
}



}

@media only screen and (max-width:377px) {
  .tabs2 p {
    font-size: 11px!important;
    margin-top: 20px;
}

}


@media only screen and (max-width:428px){
.EPR-button{
  padding: 3px 8px;
  font-size: 7px;
}

.EPR-text{
  font-size: 56%;
  text-align: center;
}
}

@media only screen and (max-width:471px){
.EPR-text{
  margin-left: 30px;
}
}


 /* 3rd section */

 .section-three .carousel .carousel-inner{

  margin-top:50px

}



/* 3rd section */

.carousel-indicators [data-bs-target] {

text-indent: -10px;

background-color:transparent;

/* margin: 0 180px;  */

}

 /* 3rd section */

.carousel.carousel-thumbs-top .carousel-indicators {

bottom: auto;

/* top: -11px; */

/* position: sticky!important; */

top: 40px;

}

@media only screen and (max-width:1000px){
  
  /* .carousel.carousel-slider .control-arrow{
    display: none ;
  } */

  .carousel .control-prev.control-arrow:before {
    border-right: 15px solid black;
    
  }

  .carousel .control-next.control-arrow:before{
    border-left: 15px solid black
  }

  .carousel.carousel-slider .control-arrow{
    background-color: rgb(0,0,0,0.5);
  }

 .carousel li.thumb.selected{
    display: block;
    min-width: 160px;
    /* margin:0 auto; */
    font-size: small;
    padding: 0 auto;;
  } 
    .carousel .thumbs{
      margin-top: -135px;;
    }


  .carousel li.thumb{
    display: none;
    
  }
}

@media only screen and (max-width:378px){

  /* .section-two-one{
    padding: 103px 0px 0px 0px;
    padding-left: 0px;
    text-align: center;
  } */
  
  #carouselExampleIndicatorsLeft #sliding .active .active_state{
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 25px solid rgb(0, 0, 0);
    background-color: transparent;
    margin:auto
  }

  .img1{
    margin-left: -22px;
  }

  }

  /* @media only screen and (max-width:575px){
    .section-two-one{
      font-size: 12px;
      padding: 54px 10px 20px 10px;
    }

    .section-two-two button{
      font-size:14px;
      padding: 6px;
    }

    .carousel.carousel-thumbs-top{
      padding-top: 0px;
    }
  } */



  .caption-1 .carousel-caption{
    position: absolute;
    right: 0;
    bottom: 28%;
    left: 68%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: left;
    bottom: 45%;
    font-size: 25px;
  }

@media (max-width:821px) and (min-width:578px){
  .caption-1 .carousel-caption{
    position: absolute;
    right: 0;
    bottom: 28%;
    left: 55%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: left;
    bottom: 55%;
    font-size: 25px;
  }
}



  .caption-1 a button{
    background-color: rgba(0, 0, 0, 0.5);
    border: 0.5px solid white;
    padding: 6px 9px;
    color: white;
    font-size: 19px;
  }


  .caption-2 .carousel-caption{
    position: absolute;
    /* right: 15%; */
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: left;
    font-size: 25px;
  }
  .caption-2 a button{
    background-color: rgba(0, 0, 0, 0.5);
    border: 0.5px solid white;
    padding: 6px 9px;
    color: white;
    font-size: 19px ;
  }

/* 
  @media only screen and (max-width:1250px){
    .caption-1 .carousel-caption{
      position: absolute;
      right: 0;
      bottom: 1.25rem;
      left: 69%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #fff;
      text-align: left;
      bottom: 57%;
      font-size: 1.6vw;
    }

  } */


  


    @media only screen and (max-width:1000px){

      div.image-content-above-read{
        display: block!important;
      }

      .image-content-above-read{
        position: absolute;
    top: -62%;
    /* left: 8.69%; */
    text-align: left;
      }
    
      div.image-content{
        display: none!important;
      }

      .bgblack {
        background-color: black;
        height: 95px;
        color: gray;
        padding: 11px;
        margin: 0 6px;
        white-space: break-spaces;
        text-align: left;
        font-size: 1.4vw;

    }
     
    .image-content-above-read p span {
      font-size: 30px!important;
      color: white;
  }

    .carousel .thumbs {
      margin-top: -118px;
  }

  /* .carousel .thumb.selected a{

    width: 92% !important;
  } */
  
  
  }


  @media only screen and (max-width:575px){
    
   
    
  .carousel .thumbs{
    margin-top: -113px;
  }

  Carousel ol,ul.thumbs{
    padding: 0 10%;
    
  }

  }


  @media only screen and (max-width:428px){
    .carousel .thumbs {
      margin-top: -104px;
  }
  }

  /* @media only screen and (max-width:376px){
   

  
  .bgblack{
      
    font-size: 2.4vw;

}



  } */

  /* @media only screen and (max-width:322px){
    .bgblack{
      
      font-size: 2.7vw;
  
  }

  } */

  .image-content p span{
    font-size:44px;
    color:white;
    }
    .image-content-above-read p span{
      font-size:3vw;
      color:white;
      }

  @media screen and (max-width:1250px) and (min-width:1000px){
    .image-content p span{
      /* font-size:2vw; */
      color:white;
      /* margin-left:200px; */
      }

      .image-content-above-read p span{
        font-size:2vw;
        color:white;
        /* margin-left:200px; */
        }

      
  }

@media screen and (max-width:999px){
  .image-content p span{
    font-size:44px;
    color:white ;
    /* margin-left:100px ; */
    }

    .image-content-above-read {
      font-size:2vw;
    color:white ;
    /* margin-left:100px ; */
    }
}



@media screen and (max-width:600px){
  /* .image-content h4{
    font-size:1.1vw;
      
    
    } */
}

.img-wrapper {
  overflow: visible;
}

img.hover-zoom {
  transition: all 0.3s ease 0s;
  /* width: 100%; */
}
img.hover-zoom:hover {
  transform: scale(1.4);
}

img.hover-zoom-in{
  transform: scale(1);
}


img.hover-zoom-in-mobile{
  transform: scale(1.2);
}
img.hover-zoom-in-mobileout{
  transform: scale(1.1);
}


@media screen and (max-width:575px){
.bgblack{
  font-size: 3.5vw;
}



.image-content h4 b{
font-size: 5vw;
}

.image-content-above-read h4 b{
  font-size: 5vw;
  }


/* .section-two-two button{
  margin:27px
} */

.caption-1 .carousel-caption{
    bottom:43%
}

}

.secondthumb{
  color:#fff;
  margin:0 50px
}


.img-road {
  /* text-align: center; */
  background-image: url("../images/Reaodcro.png");
  width: 100vw;
  height: 84vh;
  background-repeat: no-repeat;
  background-position: 49%;
  background-size: cover;
}  

.img-fish {
  /* text-align: center; */
  background-image: url("../images/Darker Salmon.png");
  width: 100vw;
  height: 84vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}  

.img-uncle {
  /* text-align: center; */
  background-image: url("../images/militarycro.png");
  width: 100vw;
  height: 84vh;
  background-repeat: no-repeat;
  background-position: 1%;
  background-size: cover;
}  

.img-plants {
  /* text-align: center; */
  background-image: url("../images/plants N.jpg");
  width: 100vw;
  height: 84vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}  

.img-meditation {
  /* text-align: center; */
  background-image: url("../images/NEWFASTINGmeditation.png");
  width: 100vw;
  height: 84vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}  


/* .remove{
  display: none;
} */

div#hide2{
  display:none;
}

div.hide1{
  display: block;
}

div.dont-display-line{
  display: none;
}

.abovecan{
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: orange;
  column-gap: 20px;
}


div a button{
  color: white;
    background: black;
    /* border: 3px solid white; */
    padding: 1px 21px;
}

.img-golf-crop {
  /* text-align: center; */
  background-image: url("../images/golferQ.png");
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 26%;
  background-size: cover;
}  

.img-snowboard {
  /* text-align: center; */
  background-image: url("../images/Snow crop.jpg");
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 70% ;
  background-size: cover;
}  

.img-old {
  /* text-align: center; */
  background-image: url("../images/Recoverycro.png");
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 23%;
  background-size: cover;
}  

.img-party {
  /* text-align: center; */
  background-image: url("../images/Party crop.jpg");
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}  


.white-line{
  background-color:white;
  height:6px;
  width:30px;
  display:inline-block
}


.land-two-button{
  padding: 6px 15px;
    background-color: orange;
    color: black;
    font-size: 20px;
    font-weight: bold;
    margin-right: 36px;
    margin-bottom: 10px;
    border: none!important
}

.land-three-button{
  padding: 6px 15px;
    background-color: orange;
    color: black;
    font-size: 20px;
    font-weight: bold;
    border: none!important
}

@media screen and (max-width:575px){
  
.land-two-button{
  padding: 0px 8px;
    background-color: orange;
    color: black;
    font-size: 16px;
    font-weight: bold;
    margin-right: 18px;
    margin-bottom: 10px;
}

.land-three-button{
  padding: 0px 8px;
    background-color: orange;
    color: black;
    font-size: 16px;
    font-weight: bold;
    border: none;
}
}