div.sub-shop{
    text-align: center;
}
div.pro-duct-cart-only h4{
    color:black;
    font-weight: bold;

}
div.product-sub-total{
    font-size: 15px;
    padding-bottom:10px
}
.check-out-btn{
    padding-top: 30px;
    padding-bottom: 30px;
}

.product-btn-default-cart {
    display: inline-block;
    font-weight: 400;
    width: 100%;
    line-height: 1.5;
    color: black;
    letter-spacing: 3px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: orange;
    border: 1px solid transparent;
    padding: 10.375rem -4.25rem;
    font-size: 18px;
    /* border-radius: 0.25rem; */
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding-right: 4rem!important;
    padding-left: 4rem!important;
}
div.review-all-leave-cart {
    
    padding-top: 114px;
}
.review-leave-cart {
    font-size: 27px;
    font-weight: bold;
    padding-top: 8px;
    padding-left: 102px;
}
.help-diff-cart {
    background-color: orange;
    height: auto;
    padding-top: 26px;
    margin-top: 109px;
    padding-bottom: 50px;
}
div.header-cart h2{
    margin-top:30px;
    margin-bottom:49px
}
div.btn-our-cart{
text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
}
.product-btn-story-cart {
    color: orange;
    border-radius: 29px;
    border-color: orange;
    background-color: white;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 3px;
    font-size: 10px;
    letter-spacing: 3px;
    border: 1px solid;
}
h4.pro-duct-your-cart {
    padding-left: 0px;
    color: orange;
    font-size: 44px;
    font-weight: bold;
    padding-top: 43px;
    padding-bottom: 33px;
}
.cart-hr-line hr{
    width: 47%;
    height:1px;
    color:grey
}
.cont-act-cart.col-sm-10 {
    padding-left: 12px;
    padding-bottom: 45px;

}
.sub-shop-cart-emp{
    padding-top: 11px;
    padding-left: 0px;

}

.cart-hr-line2 hr {
    width: 92%;
    height: 1px;
    color: grey;
    margin-top: -17px;
    margin-left: 65px;
}
.leave-one.col-sm-5{
    width: 23%;
    background-color: #8080802e;
    height: 309px;
    margin-left: 10px;

}
.arrow-cart-one{
    padding-top: 5px;
    height: 106%;
}
div.part-2-cart {
    width: 85%;
    height: 106%;

}
.opt-three{
    font-size: 15px;
    font-weight: bold;
}
.prog-bar{
    padding-top:10px;
    padding-left: 13px;
}
 a.clr-dot {
     color:black;
 }

  a:hover {color: orange}
a:active {color: orange}
.product-btn-single-carts {
    color: orange;
    border-radius: 29px;
    border-color: orange;
    background-color: white;
    padding-left: 7px;
    padding-right: 4px;
    padding-top: -3px;
    padding-bottom: -7px;
    font-size: 11px;
    letter-spacing: 1px;
    border: 1px solid;
}
button.sort-arrow-cart {
   
    width: 71%;
    padding: 0.375rem 0.75rem;
    font-size: 11px;
    font-weight: 400;
    line-height: 1rem;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid#cdd4dc;
    appearance: none;
  
}
.cart-two.col-sm-5 {
    width: 23%;
   
    height: 309px;
    margin-left: 10px;
}
.part-cart-pro{
    height: 146%;
    margin-right: 9px;
    background-color: #8080802e;
    /* width: 117%; */
    margin-left: 22px;

}
div.part-cart {
    position: relative;
    height: 300px;
    max-height: 290px;
    margin-bottom: 20px;
    overflow: hidden;
    width: 90%;
    background-color:  #8080802e;
}

.leave-one-cart.col-sm-3 {
    width: 19%;
    margin-left: 10px;
    background-color:  #8080802e;
}
.pro-part-cartt {
    padding-left: 69px;
}
.part-cart-pro-duct {
    height: 146%;
    /* margin-right: 9px; */
    background-color: #8080802e;
    /* width: 117%; */
    /* margin-left: 22px; */
}
@media only screen and (max-width:557px ) {
.pro-part-cartt {
    padding-left: 0px;
}
.product-btn-default-cart {
    
    padding-right: 4rem!important;
    padding-left: 2rem!important;
}
.cart-hr-line2 hr {
    width: 92%;
    height: 1px;
    color: grey;
    margin-top: 15px;
    margin-left: 4px;
}
.review-leave-cart {
    font-size: 25px;
    font-weight: bold;
    padding-top: -29px;
    padding-left: 12px;
    margin-top: -139px;
}
.cont-act-cart.col-sm-10 {
    padding-left: 0px!important;
    padding-bottom: 45px;
}
div.more-way{
    padding-left: 12px;
}
.prog-bar {
    padding-top: 10px;
    padding-left: 12px;
    font-size: 12px;
}
h4.pro-duct-your-cart {
    padding-left: 0px;
    
    font-size: 41px;
   
    padding-top: 5px;
    padding-bottom: 0px;
}
}