.open-button {
    background-color: #555;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 23px;
    right: 28px;
    width: 280px;
  }
  
  /* The popup form - hidden by default */
  .form-popup {
    display: none;
    position: fixed;
    bottom: 0;
    right: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
  }
  
  /* Add styles to the form container */
  .form-container {
    max-width: 300px;
    padding: 10px;
    background-color: white;
  }
  
  /* Full-width input fields */
  .form-container input[type=text], .form-container input[type=password] {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
  }
  
  /* When the inputs get focus, do something */
  .form-container input[type=text]:focus, .form-container input[type=password]:focus {
    background-color:white;
    border:1px solid grey
  }
  
  /* Set a style for the submit/login button */
  .form-container .btn {
    background-color: #04AA6D;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom:10px;
    opacity: 0.8;
  }
  .popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
   
  .box {
    position: relative;
    width: 33%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
  div.keep{
  padding-left: 36px;
  padding-bottom: 23px;
  padding-top: 20px;
  font-weight: bold;
}
div.no-acc{
  padding-left: 45px;
  font-weight: bold;
  padding-bottom: 10px;
}

div.sub-shop-addcart-log {
  padding-top: 10px;
  padding-left: 41px;
}
div.for-got{
float: right;
padding-right: 45px;
font-weight: bold;
padding-bottom: 20;
}
.product-btn-single-addcart-log {
  color: orange;
  width: 88%;
  border-radius: 29px;
  border-color: orange;
  background-color: white;
  padding-left: 76px;
  padding-right: 76px;
  padding-top: -3px;
  padding-bottom: -7px;
  font-size: 13px;
  letter-spacing: 1px;
  border: 1px solid;
}
.product-btn-default-cnfm-CON-log {
  display: inline-block;
  font-weight: 400;
  width: 88%;
  line-height: 1.5;
  color: black;
  letter-spacing: 3px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: orange;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 17px;
  /* border-radius: 0.25rem; */
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding-right: 0rem!important;
  padding-left: 0rem!important;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.continue-btn-log {
  padding-top: 20px;
  padding-left: 44px;
  padding-bottom: 42px;
}
.detail-ship-log {
  padding-left: 13px;
  padding-left: 39px;
}
  span.close-icon {
    content: 'x';
    margin-right: 86px;
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
  
  
  /* Add a red background color to the cancel button */
  /* .form-container .cancel {
    background-color: red;
  } */
  
  /* Add some hover effects to buttons */
  /* .form-container .btn:hover, .open-button:hover {
    opacity: 1;
  } */