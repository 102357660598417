.arrow-cart-one-ship {
    padding-top: 5px;
    height: 14%;
    width: 37%;
}
div.part-3-cart-ship {
    width: 53%;
    height: 70%;
    padding-left: 0px;
}
div.ship-rate{
    height: 34%;
}
div.type-method{
    font-size: 14px;
}
input.radio-btn{
    margin-inline: 26px;
}
div.boundry-wall{    padding-left: 40px;
    padding-right: 21px;
    border: 1px solid#cdd4dc;
    padding-top: 5px;
    padding-bottom: 5px;
}
div.ship-method{
    font-size: 20px;
    margin-left: -32px;
}
div.part-cart {
    position: relative;
    padding-left: -64px;
    height: 148px;
    /* max-height: 290px; */
    /* margin-bottom: 20px; */
    overflow: hidden;
    width: 65%;
    /* margin-left: 54px; */
    background-color: #8080802e;
}
.product-kgreat{
    font-size: 14px;
    margin-top: -12px;

}
.opt-three-pay{
   
        font-size: 15px;
        
    
}
.part-1-cart-ship-pay{
    width: 182px;

}
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
   
  .box {
    position: relative;
    width: 29%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
   
  

.product-price-pay{
    font-size: 24px;
}
.review-leave-ship-pay {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 13px;
    padding-top: 8px;
}
div.col-cvv{
    margin-left: -4px;
}
button.sort-arrow-cart-ship {
    /* display: block; */
    width: 138%;
    padding: 0.375rem 0.75rem;
    font-size: 11px;
    font-weight: 400;
    line-height: 1rem;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid#cdd4dc;
    appearance: none;
    /* border-radius: 0.25rem; */
    /* margin-inline: 10px; */
    /* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
}
div.part-2-cart-ship {
    width: 53%;
    height: 18%;
    padding-left: 0px;

}
.app-image{
    height: 36px;
    width: 12%;
    border-radius: 5px;

}
div.cart-two-ship{
    padding-left: 97px;
}
.product-btn-story-cart-ship {
    color: orange;
    border-radius: 29px;
    border-color: orange;
    background-color: white;
    padding-left: 11px;
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 15px;
    letter-spacing: 3px;
    border: 1px solid;
}
.inline-buttons-ship{
    padding-bottom: 12px;
    margin-left: 0px;
    margin-top: 1px;


}
#ship-ing1{
    display:block!important;
}
#ship-ing2{
    display:none!important;
}
#con-tinue{
    display:none!important;
}
.form-control-ship {
    display: block;
    width: 88%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid#cdd4dc;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.review-leave-ship {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 13px;
    padding-top: 8px;
    padding-left: -18px;
    margin-left: 0px;
}
@media only screen and (max-width:557px ) {
.app-image {
    height: 36px;
    width: 94px;
    border-radius: 5px;
}
.cont-act-cart.col-sm-10 {
    padding-left: 12px;
    padding-bottom: 4px;
}
div.join-now{
    font-size: 14px;
}
.review-leave-ship-pay-ord {
    font-size: 22px;
    font-weight: bold;
   
    padding-top: 10px;
    padding-left: 0px!important;
    margin-left: 17px;
}
div.boundry-wall {
    padding-left: 14px;
}

.order-detail-1 {
    padding-left: 0px!important     ;
}
#ship-ing2{
    display:block!important;
}
#ship-ing1{
    display:none!important;
}
div.part-cart-pay {
    
    height: 118px;
    
    width: 207%;
    /* margin-left: 54px; */
    background-color: #8080802e;
}
#con-tinue{
    display:block!important;
}
.product-kgreat {
    font-size: 10px;}
    h3.product-great {
        font-size: 17px;
        padding-top: 5px;}
        .review-leave-ship-pay-ord {
            font-size: 24px;
            font-weight: bold;
            /* padding-bottom: 13px; */
            padding-top: 20px!important;
            padding-left: -18px;
            margin-left: 0px!important;
        }
        .form-control-ship-exp-cnfm {
            display: block;
             width: 81%; 
        }
        .sub-total-cnfm {
            padding-left: 0px;
        }
}