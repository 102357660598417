@import url("https://p.typekit.net/p.css?s=1&k=afb3bbf&ht=tk&f=35204.35205.35206.35207.35208.35209.35210&a=8524055&app=typekit&e=css");




@font-face {
    font-family:"bicyclette";
    src:url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
    }

 ul.menub{
    color:white;
    padding-top:250px;
    font-weight:bold;
    font-size: 1.2vw;
    line-height: 44px;
    list-style: none;
    text-decoration:none;
} 


#hamb2{
    display:none;
}



.fa-user-circle:before {
    content: "\f2bd";
    font-size: 105px;
    padding-left: 40px;
    color: #e4e4e4;
}

p.t-info{
    
    font-weight: 600;
   
    font-size: 22px;
    padding-top: 5px;
}
.acc{
    padding: 0px;
    height: auto;
    width: auto;
    font-style: "bicyclette" ;
}
 .bil.col-sm-2{
    background:black;
    padding: 2px;
    
    height:100vh
}
input.btn-btn{
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: black;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: orange;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 20px;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
padding-right: 2rem!important;
    padding-left: 2rem!important;
   padding-top: 0.1rem;
    padding-bottom: 0.1rem;



}

/* div.contac-for {
    padding-left: 35px;
    margin-top: -6px;

} */
.fo-group {
   
    padding-top: 36px;

}
.review-leave-ship-pay-acc {
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 13px;
    padding-top: 22px;
    padding-left: -18px;
    /* padding-left: 13px; */
}
h5.bil{
    
    color: orange;
    font-size: 31px;
    font-weight: bold;
    padding-top: 65px;
    padding-bottom: 14px;

}
/* .cont-for.col-sm-6{
    padding-left:68px;
} */
label.control-label.col-sm-2{
    display: contents;
    line-height: 41px;
   
    font-size: 16px;

}
/* .form-control {
    display: block;
    width: 70%;
    padding: 0.375rem 0.75rem;
    font-size: .8rem;
    font-weight: 400;
    line-height:1rem;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid#cdd4dc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */

@media only screen and (max-width:610px ) {

input.btn-btn{
    
    font-size: 17px;
   
    padding-right: 1rem!important;
    padding-left: 1rem!important;
   
}
.bil.col-sm-2 {
    display: none!important;
}
.cont-for.col-sm-6 {
    padding-left: 39px;
}
.fo-group {
    margin-top: -14px;
    padding-left: 17px;

}
h5.bil{
    padding-left: 14px;
    padding-top: 0px;
    margin-top: -58px;
    font-size: 35px;
    padding-bottom: 0px;

}
p.t-info {
    font-weight: 600;
    font-size: 25px;
    padding-top: 5px;
    padding-left: 16px;
}
div.detail-ship-acc{
    padding-left: 16px;
}
}
@media only screen and (max-width:800px ) {
ul.menuu {
    padding-left: 11px;
    color: white;
    /* padding-top: 230px; */
    font-size: 1.7vw;
}
}