@import url("https://p.typekit.net/p.css?s=1&k=afb3bbf&ht=tk&f=35204.35205.35206.35207.35208.35209.35210&a=8524055&app=typekit&e=css");




@font-face {
    font-family:"bicyclette";
    src:url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
    }

p.ad-info{
    
    font-weight: 600;
    padding-left: 30px;
    font-size: 19px;
    padding-top: 5px;
}
ul.menr{
    color:white;
    padding-top:231px;
    font-weight:bold;
    font-size: 1.2vw;
    line-height: 44px;
    list-style: none;
    text-decoration:none;
} 

.acc{
    padding: 0px;
    height: auto;
    width: auto;
    font-style: "bicyclette" ;
}

.ootd.col-sm-2{
    background:black;
    padding: 2px;
   
    height:100vh
}
h3.samk{
    padding-left: 30px;
    color: orange;
    font-size: 31px;
    font-weight: bold;
    padding-top: 65px;
    padding-bottom: 14px;

}

div.fs-group{
    padding-left: -10px;
}
@media only screen and (max-width:610px ) {
div.fs-group{
    padding-left: 0px;
}
.ootd.col-sm-2{
    display: none!important;
}
p.ad-info {
    
    padding-left: 18px;
}
h3.samk {
    padding-left: 17px;
    font-size: 35px;
    padding-top: 0px;
    margin-top: -47px;
    padding-bottom: 0px;

}
}