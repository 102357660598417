
.all-product-order{

height: 140vh;
}
.head-order-cnfm{
    color: orange;
    font-size: 44px;
    font-weight: bold;
    padding-left: 78px;
    padding-bottom: 50px;
    padding-top: 10px;

}
.thank-you-order{
    padding-left: 78px;
    padding-bottom: 43px;
    font-size: 17px;
    font-weight: bold;

}
.cnfm-bgreat{
    padding-left: 78px;
    padding-bottom: 22px;

}
.shopping-con{
    padding-left: 78px;
    padding-bottom: 22px;

}
input.product-btn-default-confirm {
    display: inline-block;
    font-weight: 400;
    /* width: 46%; */
    line-height: 1.5;
    color: black;
    letter-spacing: 3px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: orange;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 17px;
    /* border-radius: 0.25rem; */
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding-right: 2rem!important;
    padding-left: 2rem!important;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}
.live-life-order{
    padding-left: 78px;
    padding-bottom: 25px;
    font-size: 34px;
    font-weight: 600;
}