 
@import url("https://p.typekit.net/p.css?s=1&k=afb3bbf&ht=tk&f=35204.35205.35206.35207.35208.35209.35210&a=8524055&app=typekit&e=css");




@font-face {
    font-family:"bicyclette";
    src:url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
    }

    ul.menu {
        color: black;
        color: black;
        padding-top: 36px;
        font-weight: bolder;
        font-size: 22px;
        line-height: 62px;
        padding-left: 113px;
        list-style: none;
        text-decoration: none;
        letter-spacing: 3px;
    }
    
a {
    color:white;
    text-decoration: none;
    
}
a:hover{
    color:orange;
}
 b.amb-b:hover{
     color:orange;
 }

#sv-g{
    display: block!important;
}
#strt{
    display:none!important;
}

#drop-d{
    display: none!important;
}
input#r1.react-datalist-input_text-box.undefined{
    width: 50px;
}

i.fa-user-circle:before {
    content: "\f2bd";
    font-size: 105px;
    padding-left: 15px;
    color: #e4e4e4;
}
.upim{
    padding-right: -30px;
    padding-left: 20px;
    
    font-weight:bold;
}
 
p.ac-info{
    
    font-weight: 600;
    padding-left: 17px;
    font-size: 20px;
    padding-top: 31px;
}
.form-control-ship-acc {
    display: block;
    width: 73%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 0.5rem;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid#cdd4dc;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.fa, .fas {
    font-weight: 900;
    font-size: 38px;
    
}
.detail-ship-account{
    padding-left: 18px;

}
.form-control-ship-exp-acc {
    display: block;
    width: 36%;
    float: left;
    margin-bottom: 15px;
    margin-top: -7px;
    margin-left: 16px;
    margin-inline: 4px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 0.5rem;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid#cdd4dc;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.acc{
    padding: 0px;
    height: auto;
    width: auto;
    font-style: "bicyclette" ;
}
 /* .nav.col-sm-2{
    background:black;
    padding: 2px;
    
    
} */
#dep1{
    display:block!important;
}
#dep2{
    display:block!important;
}


.contact-form label{
    font-weight:600;
}
.contact-form button{
    background: #25274d;
    color: #fff;
    font-weight: 600;
    width: 25%;
}
.contact-form button:focus{
    box-shadow:none;
} 


/* .form-control {
    display: block;
    width: 82%;
    padding: 0.375rem 0.75rem;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1rem;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid#cdd4dc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */


.contact-form label {
    font-weight: 300;
    color:black;
    font-size: 14px;
}

div.contact-fo{
    padding-left: 12px;
    padding-top: 105px;

}
div.contact-for {
    padding-left: 35px;
    margin-top: -14px;

}

label {
     display: block; 
}


label.control-label.col-sm-2{
    display: contents;
    line-height: 41px;
  
    font-size: 16px;

}
.Datalistinput.forms-control{
    display: block;
    width: 70%;
    padding: 0.375rem 0.75rem;
    font-size: .8rem;
    font-weight: 400;
    line-height:1rem;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid#cdd4dc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}
h3.aci{
    padding-left: 12px;
    color: orange;
    font-size: 31px;
    font-weight: bold;
    padding-top: 40px;
    padding-bottom: 0px;

}

div.drop-mnu{
    border: 1px solid;
    width: 43px;

}
 #last1-name{
     display:none!important;
 }
 #last2-name{
     display:block!important;
 }

 #email1-name{
     display:block!important;
 }
 #email2-name{
     display:none!important;
 }

input.btn-btn-default{
  display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color:black;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: orange;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 20px;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
padding-right: 2rem!important;
    padding-left: 2rem!important;
   padding-top: 0.1rem;
    padding-bottom: 0.1rem;


}
@media only screen and (max-width:927px ) {
    ul.menu {
        /* letter-spacing: 3px; */
        color: black;
        padding-top: 58px;
        font-weight: bolder;
        font-size: 15px;
        line-height: 62px;
        padding-left: 25px;
        list-style: none;
        text-decoration: none;
    }
}


@media only screen and (max-width:575px ) {

#strt{
    display: block!important;
}
#sv-g{
    display:none!important;
}

div.contact-fo {
    padding-left: 20px;
   margin-top: -32px;
    padding-top: 0px;
}
.acc-group {
    padding-left: 2px;
    margin-top: 14px;
}
div.join-extra{
    font-size: 12px;
    margin-left: -14px;

}
.form-control-ship-acc {
    display: block;
    width: 91%;
}
.form-control-ship-exp-acc {
    display: block;
    width: 44%;
}
}
@media only screen and (max-width:610px ) {
.nav.col-sm-2 {
  
    display: none!important;
}
#drop-d{
    display: block!important;
}
h3.aci {
    padding-left: 15px;
    margin-top: -95px
}
#dep2{
    display:block!important;
}
#dep1{
    display:none!important;
}
p.ac-info {
    
    padding-left: 15px;

}
div.contact-for {
    padding-left: 19px;}

 
input.btn-btn-default{
    
    font-size: 17px;
   
    padding-right: 2rem!important;
    padding-left: 2rem!important;
   
}

 #last1-name{
     display:block!important;
 }
 #last2-name{
     display:none!important;
 }

 #email1-name{
     display:none!important;
 }
 #email2-name{
     display:block!important;
 }
    }



    /* dropdown */
    .dropbtn {
        background-color:  rgb(0,0,0,0.25);
        color: white;
        padding: 4px;
        font-size: 17px;
        border: none;
        border-radius: 11px;
      }
      
      .dropdown {
        position: relative;
        display: inline-block;
        float: right
      }
      
      .dropdown-content {
        display: none;
        /* position: absolute; */
        background-color:white;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
      }
      
      .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }
      
      .dropdown-content a:hover {background-color: rgb(0,0,0,0.25);}
      
      .dropdown:hover .dropdown-content {display: block;}
      
      .dropdown:hover .dropbtn {background-color: rgb(0,0,0,0.25);}

      .acc-first-image{
        margin: 15px 0px;
        width: 229px;
        height: 38px;
    }
      