@font-face {
    font-family:"bicyclette";
    src:url("https://use.typekit.net/af/e8d041/00000000000000007735ace5/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/e8d041/00000000000000007735ace5/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/e8d041/00000000000000007735ace5/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
    }

.header{
    display: flex;
    background-color: transparent;
    background: none;
    position: fixed;
    width: 100vw;
    top: 0;
    margin: 0px;
    margin-top: 0px;
    z-index: 100;
}

.ghostItem{
    border: 0px solid transparent;
    padding: 5px;
}
.revealItems{
    border: 0px solid transparent;
    padding: 5px;
}

.header_logo{
    height: auto;
    width: 4rem;
    /* margin-top: 15px; */
    margin-left: 2rem;
}

.header_logo img{
    height: 45px;
    margin: 20px 0;
    position: relative;
    width: 229px;
}


.header_center{
    flex: 1;
    display: flex;
    justify-content: right;
}

.header_center p{
    /* margin: 10px; */
	/* margin-top: 1.5rem; */
	font-weight: 600;
}

.header_right{
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
	font-weight: 600;
	
}

.header_right p{
    margin: 10px;
	margin-top: 1.5rem;
}

.ghostItem{
    border: solid 1px transparent;
    /* border-radius: 10px; */
    padding: 5px 23px;
    font-size: 17px;
    color: white;
    background-color:rgb(0,0,0,0.2);
    height: fit-content;
}

.ghostItem:hover{
    text-decoration: underline;
    text-decoration-color: orange;
    text-decoration-thickness: 4px;
}

.revealItems:hover {
    background-color: rgba(255, 166, 0, 0.745) !important;
    border: 1px solid rgba(255, 166, 0, 0.745) !important;
	transition:rgba(255, 166, 0, 0.745) .33s ease;
}

.revealItems {
    background-color: rgba(255, 166, 0, 0.745) !important;
    border: 1px solid rgba(255, 166, 0, 0.745) !important;
	transition:rgba(255, 166, 0, 0.745) .33s ease;
    box-shadow: none !important;
}

.revealGhostItems{
    display: none;
}

@media(max-width: 1200px){
    .ghostItem{
        display: none;
    }
    .revealItems{
        background-color: orange;
        border: solid 1px transparent;
        padding: 5px;
        border-radius: 10px;
    }
    .revealGhostItems{
        display: block;
        /* margin-right: 15px; */
        margin: auto 0px;
    }
    .menu{
        /* background-color: orange !important; */
        /* padding-top: 8px !important; */
        font-size: 32px !important;
        height: 35px;
        border: solid transparent 0px;
        border-radius: 15px !important;
    }
}

.listItem{
    margin-bottom: 12px;
    margin-left: 10%;
    font-weight: bold;
    font-size: 30px;
    text-decoration: none;
    color: white;
}

.navbar-toggler:focus{
    box-shadow: none;
}

@media(max-width: 1200px){
    .header_logo img{
        width: 200px;
        margin-left: 0px;
    }

    .nav-link{
        padding: 0px;
        font-size: 30px!important;
        /* font-weight: bolder; */
        font-weight: bold;
        background-color: transparent!important;
    }

}

.navbar-toggler{
    padding: 0rem 0.75rem
}

.offcanvas{
    background-color: orange;
}

.dropdown-menu{
    text-align: right;
    background-color: orange;
    border: none
    ;
}

.nav-link {
    color: white;
}

.dropdown-item{
    color:white;
    font-size: 20px;
}

.listItem:hover{
    color: black;
}

.nav-link:hover{
    color: white;
    text-decoration-color: orange;
}

.nav-link{
    padding: 6px 23px;
    font-size: 17px;
    background-color: rgb(0,0,0,0.2);
    font-weight: 600;
}

.nav-link:focus, .nav-link:hover{
    color:white;
    text-decoration: underline;
    text-decoration-thickness: 4px;
    text-decoration-color: orange;
}

.dropdown-item {
    font-size: 17px;
    font-weight: 600;
}