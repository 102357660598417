@import url("https://p.typekit.net/p.css?s=1&k=afb3bbf&ht=tk&f=35204.35205.35206.35207.35208.35209.35210&a=8524055&app=typekit&e=css");




@font-face {
    font-family:"bicyclette";
    src:url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
    }

p.ak-info{
    
    font-weight: 600;
    padding-left: 40px;
    font-size: 19px;
    padding-top: 5px;
}

h3.sam{
    padding-left: 35px;
    color: orange;
    font-size: 35px;
    font-weight: bold;
    padding-top: 38px;
    padding-bottom: 14px;

}
.acc{
    padding: 0px;
    height: auto;
    width: auto;
    font-style: "bicyclette" ;
}

ul.menuo{
   
    letter-spacing: 3px;
        color: black;
        padding-top: 58px;
        font-weight: bolder;
        font-size: 22px;
        line-height: 62px;
        padding-left: 113px;
        list-style: none;
        text-decoration: none;
    }
div.col-3.pro{
    float: left;
    width: 48%;
   font-weight: bold;
    height: auto;
    padding-left: 41px;

}

div.col-1.qt{
    float: left;
    width: 9%;
    font-weight: bold;
    height: auto; 
    
}
div.col-2.sp{
      
    float: left;
    width: 14%;
    font-weight: bold;
    height: auto; 
  

}
div.col-2.od{
    float: left;
    width: 14%;
    font-weight: bold;
    height: auto
    
}
div.col-2.dl{
    float: left;
    width: 12%;
    font-weight: bold;
    height: auto
    
}
.ood.col-sm-2{
    background:black;
    padding: 2px;
   
    height:100vh
}
.contact-for.col-sm-10{
    padding-left: 38px;

}
@media only screen and (max-width:927px ) {
    ul.menuo {
        /* letter-spacing: 3px; */
        color: black;
        padding-top: 58px;
        font-weight: bolder;
        font-size: 15px;
        line-height: 62px;
        padding-left: 25px;
        list-style: none;
        text-decoration: none;
    }
}

    
@media only screen and (max-width:610px ) {

.ood.col-sm-2 {
    display: none!important;
}
.contact-for.col-sm-10 {
    padding-left: 0px;
}
div.col-3.pro {
    width: 31%;
    padding-left: 13px;
}
div.col-2.sp {
   
    width: 21%;
    
}
h3.sam {
   
    padding-left: 10px;
    padding-top: 0px;
    margin-top: -70px;
    font-size: 33px;
    padding-bottom: 0px;

}
p.ak-info {
    font-size: 22px;
    padding-left: 13px;
    padding-bottom: 15px;
}
}