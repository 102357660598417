.all-product{
    height:auto
}

h4.pro-duct{
    padding-left: 0px;
    color: orange;
    font-size: 44px;
    font-weight: bold;
    padding-top: 43px;
    padding-bottom: 10px;
    margin-left: -37px;


}
div.ourt-story{
    padding-left: 30px;
    font-weight: bold;

}
.keto h4{
    font-weight: 600;
    padding-left: 490px;
}
div.our-story{
    padding-left: 490px;
    font-weight: 600;
   
    text-align: left;


}

.image-story{
    padding-top: 22px;
}
#image-product {
   
    background-image: url("../images/mountain.jpeg");
    width: 100vw;
    height: 77vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }  
div.our-story-part{
    padding-left: 0px;
    padding-right: 2px;
    padding-top: 38px;

}
input.product-btn-default{
    display: inline-block;
    font-weight: 400;
    width: 100%;
    line-height: 1.5;
    color:black;
    letter-spacing: 3px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: orange;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 17px;
    /* border-radius: 0.25rem; */
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
padding-right: 3rem!important;
    padding-left: 3rem!important;
   padding-top: 0.1rem;
    padding-bottom: 0.1rem;



}


.product-btn-story{
    color: orange;
    border-radius: 29px;
    border-color: orange;
    background-color: white;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 14px;
    letter-spacing: 3px;
    border: 1px solid

}
#story2{
    display:none!important;
}
#story1{
    display:block!important;
}
input.product-btn-help{
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color:orange;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: black;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 18px;
    letter-spacing: 2px;
    /* border-radius: 0.25rem; */
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
padding-right: 1rem!important;
    padding-left: 1rem!important;
   padding-top: 0.1rem;
    padding-bottom: 0.1rem;



}
@import url("https://p.typekit.net/p.css?s=1&k=afb3bbf&ht=tk&f=35204.35205.35206.35207.35208.35209.35210&a=8524055&app=typekit&e=css");




@font-face {
    font-family:"bicyclette";
    src:url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/7accc3/00000000000000007735acec/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
    }

a,
a:hover {
    text-decoration: none;
    color: inherit;
}

.section-products {
    padding: 80px 0 54px;
}

.section-products .header {
    margin-bottom: 30px;
    margin-top: 10px
}

.section-products .header h3 {
    font-size: 1rem;
    color: #fe302f;
    font-weight: 500;
}

.section-products .header h2 {
    font-size: 2rem;
    font-weight: 400;
    color: #444444; 
}

.section-products .single-product {
    margin-bottom: 26px;
}

.section-products .single-product .part-1 {
    position: relative;
    height: 300px;
    max-height: 290px;
    margin-bottom: 20px;
    overflow: hidden;
    width:90%
}

.section-products .single-product .part-1::before {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 95%;
		height: 100%;
		z-index: -1;
		transition: all 0.3s;
}

div.part-2{
    width:85%
}
button.product-btn-default {
    display: inline-block;
    font-weight: 400;
    width: 100%;
    line-height: 1.5;
    color: black;
    letter-spacing: 3px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: orange;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 17px;
    /* border-radius: 0.25rem; */
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding-right: 3rem!important;
    padding-left: 3rem!important;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
}
.quickadd {
    width: 80vw;
    height: 200vh;
    position: fixed;
    top: -16px;
    right: 0;
    bottom: 0;
    background-color:white; 
    
  }
  
  .quickadd div {
    cursor: pointer;
    
    left: 1em;
    top: 1em;
  }
  
  
.section-products #product-1 .part-1::before {
    
    background-size: cover;
        transition: all 0.3s;
        background-color: #8080802e;
}

.section-products #product-2 .part-1::before {
   
    background-size: cover;
    background-color: #8080802e;
}

.section-products #product-3 .part-1::before {
    /* background: url("https://i.ibb.co/L8Nrb7p/1.jpg") no-repeat center; */
    background-size: cover;
    background-color: #8080802e;
}

.section-products #product-4 .part-1::before {
    /* background: url("https://i.ibb.co/cLnZjnS/2.jpg") no-repeat center; */
    background-size: cover;
    background-color:#8080802e;
}

.section-products .single-product .part-1 .discount,
.section-products .single-product .part-1 .new {
    position: absolute;
    top: 15px;
    left: 20px;
    color: #ffffff;
    background-color: #fe302f;
    padding: 2px 8px;
    text-transform: uppercase;
    font-size: 0.85rem;
}

.section-products .single-product .part-1 .new {
    left: 0;
    background-color: #444444;
}

.section-products .single-product .part-1 ul {
    position: absolute;
    bottom: -41px;
    left: 20px;
    margin: 0;
    padding: 0;
    list-style: none;
    opacity: 0;
    transition: bottom 0.5s, opacity 0.5s;
}
div#product-big.single-product{
    width: 188%;
    background-color: #8080802e;

}

.section-products .single-product:hover .part-1 ul {
    bottom: 30px;
    opacity: 1;
}

.section-products .single-product .part-1 ul li {
    display: inline-block;
    margin-right: 4px;
}

.section-products .single-product .part-1 ul li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #ffffff;
    color: #444444;
    text-align: center;
    box-shadow: 0 2px 20px rgb(50 50 50 / 10%);
    transition: color 0.2s;
}

.section-products .single-product .part-1 ul li a:hover {
    color: #fe302f;
}

.section-products .single-product .part-2 .product-title {
    font-size: 1rem;
}

.section-products .single-product .part-2 h4 {
    display: inline-block;
    font-size: 1rem;
}

.section-products .single-product .part-2 .product-old-price {
    position: relative;
    padding: 0 7px;
    margin-right: 2px;
    opacity: 0.6;
}

.section-products .single-product .part-2 .product-old-price::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #444444;
    transform: translateY(-50%);
}

.help-diff{
    background-color: orange;
    height: auto;
    padding-top: 26px;
    margin-top: 60px;
    padding-bottom: 50px;
   

}
@media only screen and (max-width:575px ) {
h4.pro-duct{
    padding-left: 34px;
    
    font-size: 42px;
   
    padding-top: 0px;
}
.section-products .single-product .part-1::before {
  
    width: 95%;
  
}
div#product-big.single-product {
    width: 93%;
}
.keto-one-h{
    margin-right:-30px
}
#image-product {
   
    background-image: url("../images/mountain.jpeg");
    width: 100vw;
    height: 30vh;
}
.keto h4{
    font-weight: 600;
    padding-left: 7px;
}
div.our-story{
    padding-left: 7px;
    font-weight: 600;
    padding-right: 4px;
    text-align: left;


}
button.sort-of {
   
    float: left!important;
}
.sort-by{
    padding-bottom:40px
}
.section-products {
    /* padding: 60px 10px 54px; */
    /* margin-right: -35px; */
}
div.part-2{
    width:96%
}
#story1{
    display:none!important;
}
#story2{
    display:block!important;
}
div.ourt-story {
    padding-left: 4px;
    /* text-align: left; */
}
div.btn-our{
    text-align: center;
    margin-bottom: -20px;

}
div.col-sm-2.col-lg-4.col-xl-3{
    flex-shrink: 0;
    /* width: 47%; */
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);

}
input.product-btn-help{
    padding-right: 1rem!important;
    padding-left: 1rem!important;
}
div.sort-by {
    margin-left: 0px!important;
}
button.sort-of{
    display: block;
    width: 37%!important;
}
input.product-btn-default {
    width: 100%;
    letter-spacing: 2px;
    font-size: 3.2vw;
    padding-right: 1rem!important;
    padding-left: 1rem!important;
    
}
.section-products .single-product .part-1 {
    position: relative;
    height: 240px;
    width: 100%
} 
input.product-btn-help {
   
    font-size: 3.5vw;
    
}

}
.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    
  }
  .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
  }

  button.sort-of{
    display: block;
    width: 11%;
    float: right;
    padding: 0.375rem 0.75rem;
    font-size: 19px;
    font-weight: 400;
    line-height:1rem;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid#cdd4dc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* border-radius: 0.25rem; */
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  }

  div.sort-by{
    margin-left: 277px;
   
    margin-bottom: -16px;
}